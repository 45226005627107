import React, { useContext } from 'react';
import { RailsBlock, RailsPageView } from '~/typings/services/rails/tile';

interface IPageViewDataContext {
  /** @deprecated */
  data: any[] | null;

  pageView?: RailsPageView | null;
}

export const PageViewDataContext = React.createContext<IPageViewDataContext>({
  data: null,
});

export function usePageViewData<D extends any = any>(
  block?: RailsBlock,
): { allData: any[] | null; data: null | D; pageView?: RailsPageView | null } {
  const context = useContext(PageViewDataContext);

  if (context?.data && block?.id != null) {
    return {
      allData: context.data,
      data: context.data[block.id] || null,
      pageView: context.pageView,
    };
  }

  return { data: null, pageView: context.pageView, allData: context.data };
}
