export default function getLanderId(path: string[]): number | null {
  const landerId = Number(
    path.reduce(
      (_, pathPart: string) => pathPart.match(/^l(\d+)$/)?.[1] ?? '',
      '',
    ),
  );

  if (Number.isNaN(landerId)) {
    return null;
  }

  return landerId;
}
